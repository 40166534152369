import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {

  connect() {
    console.log("Setting up sidebar");
    let settings = {
      dimPage: false,
      closable: false
    };
    $(this.element).sidebar(settings)
                   // .sidebar("toggle");
  }

  disconnect() {
    console.log("Destroy sidebar");
    $(this.element).sidebar("destroy");
  }
  
}
