import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

import Timeago from '@stimulus-components/timeago';
import AnimatedNumber from '@stimulus-components/animated-number';
import Notification from '@stimulus-components/notification';
import AutoSubmit from '@stimulus-components/auto-submit';
import TextareaAutogrow from 'stimulus-textarea-autogrow';
// import Popover from '@stimulus-components/popover';


// Start Stimulus application
const application = Application.start();

// Load and register global controllers
registerControllers(
  application,
  import.meta.glob('../controllers/*_controller.{ts,js}', { eager: true }),
);


// Load and register view_component controllers
// registerControllers(
//   application,
//   import.meta.glob('../../components/**/*_controller.{ts,js}', { eager: true }),
// );


// Register external Stimulus components
application.register('timeago', Timeago);
application.register('animated-number', AnimatedNumber);
application.register('notification', Notification);
application.register('auto-submit', AutoSubmit);
application.register('textarea-autogrow', TextareaAutogrow);
// application.register('popover', Popover);
