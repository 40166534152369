import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "search" ];

  // Prevent default ctrl+f behaviour, focus
  focus(event) {
    
    if (!this.hasSearchTarget) {
      return
    }

    event.preventDefault();
    const element = this.searchTarget;
    // element.focus();
    element.select();
  }
  
}
