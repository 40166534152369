import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {

  static values = { url: String };

  connect() {
    console.log("Connecting controller to search: ", this.urlValue);
    
    $(this.element).search({
      apiSettings: {
        url: this.urlValue
      },
      minCharacters: 3,
      showNoResults: true,
      type: "category"
    });

  }

  disconnect() {
    $(this.element).search("destroy");
  }
  
}
