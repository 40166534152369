import { Controller } from "@hotwired/stimulus";
import $ from "jquery";
import { debounce } from '/utils/utils.ts';

export default class extends Controller {

  static targets = [ "dropdown" ];

  connect() {
    // Every Fomantic-UI dropdown has a corresponding hidden input
    const hiddenInputElement = $(this.dropdownTarget).find($.fn.dropdown.settings.selector.input)[0];
    const form = hiddenInputElement.form;

    const submitForm = debounce(() => { form.requestSubmit() }, 150);

    $(this.dropdownTarget).dropdown({
      onChange: (text, value, element) => {
        submitForm();
      }
    });
  }

  clear() {
    $(this.dropdownTarget).dropdown("clear");
  }
  
}
