// console.log('Vite ⚡️ Rails');

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>




// Example: Load Rails libraries in Vite.
//
import "@hotwired/turbo-rails";
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')
import.meta.glob('../channels/**/*_channel.ts', { eager: true });

// import '../utils/setupServiceWorker.ts';
import '../utils/setupStimulus.ts';
import '../utils/setupViewTransitions.ts';

// import jQuery from "jquery";
// globalThis.jQuery = jQuery;
// window.jQuery = jQuery;


// Importing CSS from JS with Vite!
// import 'fomantic-ui/dist/semantic.min.css';

// Import with side-effects - no CommonJS or ESM
import 'fomantic-ui/dist/semantic.min.js';

import $ from 'jquery';

$(document).on('turbo:load', function() {
    console.log('Loaded turbo links');
});
