import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {

  connect() {
    $(this.element).progress();
  }

  disconnect() {
    $(this.element).progress("destroy");
  }
  
}
