import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {

  connect() {
    console.log("Bound to: ", this.element);
    let context = this.element.dataset.context;
    console.log("Context: ", context);
    // $(this.element).sticky({
    //   context: context
    // });
  }
  
}
