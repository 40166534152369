import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {

  // static values = { open: Boolean }

  connect() {
    $(this.element).accordion();
  }

  // openValueChanged() {
  //   $(this.element).accordion()
  // }
  
}
